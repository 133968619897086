export const marketPlaces = {
  opensea: 'assets/landing-page-icons/opensea-icon.png',
  foundation: 'assets/landing-page-icons/foundation-icon.png',
  objkt: 'assets/landing-page-icons/objkt-icon.png',
  magicEden: 'assets/landing-page-icons/magic-eden-icon.png',
};

export const nftCards = {
  nftCard1: 'assets/landing-page-icons/nft-cards-1.png',
  nftCard2: 'assets/landing-page-icons/nft-cards-2.png',
  nftCard3: 'assets/landing-page-icons/nft-cards-3.png',
  nftCard4: 'assets/landing-page-icons/nft-cards-4.png',
};

export const chains = {
  tezos: 'assets/landing-page-icons/tezos-icon.png',
  ethereum: 'assets/landing-page-icons/ethereum-icon.png',
  solana: 'assets/landing-page-icons/solana-icon.png',
};

export const profiles = {
  profile1: 'assets/landing-page-icons/dp-icons-1.png',
  profile2: 'assets/landing-page-icons/dp-icons-2.png',
  profile3: 'assets/landing-page-icons/dp-icons-3.png',
};
