import { NextSeo } from 'next-seo';
import { APP_ENVIRONMENT, APP_URL } from '../../config';
import { LandingPage } from '../components/pages/landing-page';

export default function Index() {
  return (
    <>
      <NextSeo
        title="Hoji"
        defaultTitle={'Hoji'}
        noindex={APP_ENVIRONMENT !== 'production'}
        nofollow={APP_ENVIRONMENT !== 'production'}
        description="The Profile Page for NFT creators to Showcase and Sell. Organize your Creations & Collections from multiple marketplaces across chains."
        openGraph={{
          title: 'Hoji',
          description:
            'The Profile Page for NFT creators to Showcase and Sell. Organize your Creations & Collections from multiple marketplaces across chains.',
          url: APP_URL,
          site_name: 'Hoji',
          images: [
            {
              url: `${APP_URL}/assets/default-cover.png`,
              alt: 'Hoji',
            },
          ],
        }}
        twitter={{
          handle: '@hojixyz',
          site: '@hojixyz',
          cardType: 'summary_large_image',
        }}
      />
      <LandingPage />
    </>
  );
}

Index['layout'] = 'normal';
