import { Variants } from 'framer-motion';
import { ProfileBioSkeleton } from '../../icons/landing-page-icons';
import { AnimationLayout } from '../animation-layout';

export function ProfileBioSkeletonAnimation() {
  const variants: Variants = {
    hidden: {
      opacity: 0,
      x: '100vw',
    },
    animate: {
      opacity: [0, 1],
      x: [380, 25],
      y: -700,
      transition: {
        delay: 1,
        duration: 4,
        type: 'spring',
        stiffness: 40,
      },
    },
  };
  return (
    <>
      <AnimationLayout variants={variants}>
        <ProfileBioSkeleton className="absolute" />
      </AnimationLayout>
    </>
  );
}
