import { Variants } from 'framer-motion';
import { nftCards } from '../../../helpers/animation.helper';
import { ImageAnimationLayout } from '../image-animation-layout';
interface NFTImageAnimationProps {
  nftCard: string;
  animatedX: number | number[] | string;
  animatedY: number | number[] | string;
  animatedOpacity?: number | number[];
  hiddenX?: number | number[] | string;
  hiddenY: number | number[] | string;
  hiddenOpacity?: number | number[];
  delay: number;
  duration: number;
}

export function NFTImageAnimation({
  animatedX,
  animatedY,
  animatedOpacity = [0, 0, 1, 0, 0],
  hiddenX = '100vw',
  hiddenY,
  hiddenOpacity = 0,
  delay,
  duration,
  nftCard,
}: NFTImageAnimationProps) {
  const variants: Variants = {
    hidden: {
      opacity: hiddenOpacity,
      x: hiddenX,
      y: hiddenY,
    },
    animate: {
      opacity: animatedOpacity,
      x: animatedX,
      y: animatedY,
      z: 10,
      transition: {
        delay,
        duration,
        // type: 'spring',
        ease: 'easeInOut',
        stiffness: 40,
        repeat: Infinity,
      },
    },
  };
  return (
    <ImageAnimationLayout
      imgProps={{
        src: nftCards[nftCard],
        alt: nftCard,
        className: 'w-[118px] h-[166px] rounded-md shadow-lg absolute',
      }}
      variants={variants}
    />
  );
}
