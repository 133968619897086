import { Button } from 'antd';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
const AnimatedText = dynamic(() => import('../../utilities/animated-text'), {
  ssr: false,
});

export const GetStarted = () => {
  const { push } = useRouter();
  const words = ['Trusted, Easy, and Powerful'];

  return (
    <div className="grid grid-cols-1 py-10 md:py-20 ">
      <motion.div className="text-[#2A2438] text-4xl text-center font-semibold place-self-center home-text">
        <AnimatedText
          words={words}
          animationType="wave"
          delay={8000}
          type="words"
        />
      </motion.div>
      <div className="place-self-center mt-5">
        <Button
          type="primary"
          className="rounded-md h-12 text-xl w-44 text-white font-semibold home-btn"
          onClick={() => push('/login')}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
