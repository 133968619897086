import { Grid } from 'antd';
import { ChainAnimation } from './chain-animation';
const { useBreakpoint } = Grid;
export function BlockchainAnimations() {
  const { xs } = useBreakpoint();
  return (
    <>
      <ChainAnimation
        key="ethereum"
        animatedX={xs ? [258, 60, -258] : [374, 60, -374]}
        animatedY={xs ? -370 : -374}
        hiddenY={xs ? -370 : -374}
        delay={12}
        duration={12}
        chain="ethereum"
      />
      <ChainAnimation
        key="tezos"
        animatedX={xs ? [258, 204, -258] : [374, 200, -374]}
        animatedY={xs ? -370 : -374}
        hiddenY={xs ? -370 : -374}
        delay={12}
        duration={12}
        chain="tezos"
      />
      <ChainAnimation
        key="solana"
        animatedX={xs ? [258, 60, -258] : [374, 60, -374]}
        animatedY={xs ? -192 : -196}
        hiddenY={xs ? -192 : -196}
        delay={12}
        duration={12}
        chain="solana"
      />
    </>
  );
}
