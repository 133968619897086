import { Grid } from 'antd';
import { NFTProfilePictureAnimation } from './nft-profile-picture-animation';
const { useBreakpoint } = Grid;
export function ProfilePictureAnimations() {
  const { xs } = useBreakpoint();
  return (
    <>
      <NFTProfilePictureAnimation
        key="profile1"
        animatedX={xs ? 122 : [246, 127, -246]}
        animatedY={xs ? -684 : -680}
        hiddenY={xs ? -540 : -680}
        delay={4}
        duration={12}
        profile="profile1"
        xs={xs}
      />
      <NFTProfilePictureAnimation
        key="profile2"
        animatedX={xs ? 122 : [246, 127, -246]}
        animatedY={xs ? -684 : -680}
        hiddenY={xs ? -540 : -680}
        delay={8}
        duration={12}
        profile="profile2"
        xs={xs}
      />
      <NFTProfilePictureAnimation
        key="profile3"
        animatedX={xs ? 122 : [246, 127, -246]}
        animatedY={xs ? -684 : -680}
        hiddenY={xs ? -540 : -680}
        delay={12}
        duration={12}
        profile="profile3"
        xs={xs}
      />
    </>
  );
}
