import { FeaturedCreators } from './featured-creators';
import { GetStarted } from './get-started';
import { HeroElement } from './hero-elements';
import { LandingPageFooter } from './landing-page-footer';

export function LandingPage() {
  return (
    <div className="w-full mt-0 h-full bg-primary flex flex-col justify-center overflow-hidden">
      <HeroElement />
      <FeaturedCreators />
      <GetStarted />
      <LandingPageFooter />
    </div>
  );
}
