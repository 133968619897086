import { Button, Typography } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { HeroAnimatedElement } from './hero-animated-element';
const AnimatedText = dynamic(() => import('../../utilities/animated-text'), {
  ssr: false,
});

export const HeroElement = () => {
  const { push } = useRouter();
  const words = ['Shill', 'Showcase', 'Sell'];

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 home-text">
      <div className=" p-10 xl:px-28 2xl:px-48 text-center md:text-start md:mt-28">
        <div>
          <Typography.Text className="text-3xl md:text-5xl font-medium transition-transform ease-linear home-text">
            Web3 Link in Bio
          </Typography.Text>
          <Typography className="font-extrabold text-3xl md:text-5xl transition-transform ease-linear my-3 home-text">
            <AnimatedText words={words} />
          </Typography>
          <Typography.Text className="text-3xl md:text-5xl font-medium home-text">
            your creations!
          </Typography.Text>
        </div>
        <div className="mt-5 mb-6 md:mb-20">
          <Typography.Text className="text-xl md:text-3xl font-normal home-text">
            Aggregate your creations across chains and marketplace!
          </Typography.Text>
        </div>
        <Button
          size="large"
          type="primary"
          className="font-semibold rounded-md w-64 h-12 text-white text-xl transition-transform hover:scale-105 ease-linear shadow-md home-btn"
          onClick={() => push('/login')}
        >
          Sign up
        </Button>
      </div>

      <div className="place-self-center lg:place-self-center lg:mr-44">
        {/* <LandingPageLogos className="text-[30rem] md:text-[50rem] lg:text-[60rem]" /> */}
        <HeroAnimatedElement />
      </div>
    </div>
  );
};
