import { AnimatePresence, motion, Variants } from 'framer-motion';
import { ReactNode } from 'react';

interface AnimationLayoutProps {
  variants: Variants;
  children: ReactNode;
}

export function AnimationLayout({ variants, children }: AnimationLayoutProps) {
  return (
    <AnimatePresence>
      <motion.div variants={variants} animate="animate" initial="hidden">
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
