import { Grid } from 'antd';
import { NFTImageAnimation } from './nft-image-animation';
const { useBreakpoint } = Grid;
export function NFTCardAnimations() {
  const { xs } = useBreakpoint();
  return (
    <>
      <NFTImageAnimation
        key="nftCard1"
        animatedX={xs ? [258, 50, -258] : [358, 50, -358]}
        animatedY={-405}
        hiddenY={-405}
        delay={4}
        duration={12}
        nftCard="nftCard1"
      />
      <NFTImageAnimation
        key="nftCard2"
        animatedX={xs ? [258, 194, -258] : [358, 196, -358]}
        animatedY={-405}
        hiddenY={-405}
        delay={4}
        duration={12}
        nftCard="nftCard2"
      />
      <NFTImageAnimation
        key="nftCard3"
        animatedX={xs ? [258, 50, -258] : [358, 50, -358]}
        animatedY={-228}
        hiddenY={-228}
        delay={4}
        duration={12}
        nftCard="nftCard3"
      />
      <NFTImageAnimation
        key="nftCard4"
        animatedX={xs ? [258, 194, -258] : [358, 196, -358]}
        animatedY={-228}
        hiddenY={-228}
        delay={4}
        duration={12}
        nftCard="nftCard4"
      />
    </>
  );
}
