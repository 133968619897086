import { Variants } from 'framer-motion';
import { chains } from '../../../helpers/animation.helper';
import { ImageAnimationLayout } from '../image-animation-layout';
interface ChainAnimationProps {
  chain: string;
  animatedX: number | number[] | string;
  animatedY: number | number[] | string;
  animatedOpacity?: number | number[];
  hiddenX?: number | number[] | string;
  hiddenY: number | number[] | string;
  hiddenOpacity?: number | number[];
  delay: number;
  duration: number;
}

export function ChainAnimation({
  animatedX,
  animatedY,
  animatedOpacity = [0, 0, 1, 0, 0],
  hiddenX = '100vw',
  hiddenY,
  hiddenOpacity = 0,
  delay,
  duration,
  chain,
}: ChainAnimationProps) {
  const variants: Variants = {
    hidden: {
      opacity: hiddenOpacity,
      x: hiddenX,
      y: hiddenY,
    },
    animate: {
      opacity: animatedOpacity,
      x: animatedX,
      y: animatedY,
      z: 10,
      transition: {
        delay,
        duration,
        // type: 'spring',
        ease: 'easeInOut',
        stiffness: 40,
        repeat: Infinity,
      },
    },
  };
  return (
    <ImageAnimationLayout
      imgProps={{
        src: chains[chain],
        alt: chain,
        className: 'h-24 w-24 rounded-full shadow-lg absolute',
      }}
      variants={variants}
    />
  );
}
