/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { Variants } from 'framer-motion';
import { ImgHTMLAttributes } from 'react';
import { AnimationLayout } from './animation-layout';

interface ImageAnimationLayoutProps {
  imgProps: ImgHTMLAttributes<HTMLImageElement>;
  variants: Variants;
}

export function ImageAnimationLayout({
  imgProps,
  variants,
}: ImageAnimationLayoutProps) {
  return (
    <AnimationLayout variants={variants}>
      <img {...imgProps} />
    </AnimationLayout>
  );
}
