import { Avatar, Grid, Modal, Typography } from 'antd';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { userList } from '../../../constants/featured-user.constant';
import { themeStyles } from '../../../constants/themes.constant';
import {
  GetFeaturedUsersListQuery,
  useGetFeaturedUsersListQuery,
} from '../../../graphql/users.generated';
import { getFileBasePath } from '../../../helpers/storage.helper';
import { CheckLogo } from '../../icons';
import { Enum_Store_Theme_Enum } from '../../nfts/user-nfts.generated';
import { StoreFrontPage } from '../store-front-page';

const { useBreakpoint } = Grid;
export const FeaturedCreators = () => {
  const { xs } = useBreakpoint();

  const [selected, setSelected] = useState<
    GetFeaturedUsersListQuery['users'][0] | undefined
  >();
  const { data } = useGetFeaturedUsersListQuery({
    variables: {
      user_list: xs ? userList.slice(0, 16) : userList,
    },
    skip: userList.length === 0,
  });
  useEffect(() => {
    if (data && !xs) {
      setSelected(data.users[0]);
    }
  }, [data, xs]);
  return (
    <div
      className={`px-10 xl:px-20 2xl:px-48 grid grid-cols-1 lg:grid-cols-3 bg-[#2A2438] ${
        data && data?.users?.length > 0 && 'py-10'
      }`}
    >
      {data && data?.users?.length > 0 && (
        <div className="col-span-2">
          <div className="text-5xl font-semibold text-white mb-5 lg:mb-16 text-center md:text-start">
            Featured Creators
          </div>
          <div className="grid grid-cols-3 xl:grid-cols-5 items-start gap-5 2xl:gap-10 ">
            {data?.users?.map((item, key) => (
              <div
                className={`flex flex-col justify-center items-center cursor-pointer 
              }`}
                key={key}
                onClick={() => setSelected(item)}
              >
                <div
                  className={`flex flex-col items-center justify-center text-center rounded-full ${
                    selected?.id === item?.id ? 'scale-125' : ''
                  }`}
                >
                  <Avatar
                    size={xs ? 80 : 90}
                    className="drop-shadow-lg rounded-full profile-border hover:scale-110 transition-all duration-300 border-3 border-solid border-white "
                    src={
                      <img
                        src={
                          getFileBasePath(item?.avatar_object?.storage_url) ??
                          'assets/profile.png'
                        }
                        alt="nice avatar"
                        onError={(e) => {
                          e.currentTarget.onerror = null;
                          e.currentTarget.src = 'assets/profile.png';
                        }}
                      />
                    }
                  />
                  <Typography.Text className="text-white" strong>
                    {item.username} <CheckLogo className="w-3 h-3" />
                  </Typography.Text>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="justify-self-end">
        {!xs && (
          <div className=" w-full col-span-1 rounded-md bg-white">
            {selected && data && data?.users?.length > 0 && (
              <ThemeSwitcherProvider
                themeMap={themeStyles}
                defaultTheme={Enum_Store_Theme_Enum.BonJour}
                insertionPoint="styles-insertion-point"
              >
                <div className="w-[400px]">
                  <StoreFrontPage
                    storeFront={selected?.username as string}
                    themesView={true}
                  />
                </div>
              </ThemeSwitcherProvider>
            )}
          </div>
        )}
      </div>
      {xs && selected && data && data?.users?.length > 0 && (
        <Modal
          footer={null}
          visible={!isUndefined(selected)}
          destroyOnClose
          onCancel={() => setSelected(undefined)}
        >
          <ThemeSwitcherProvider
            themeMap={themeStyles}
            defaultTheme={
              selected?.store_theme || Enum_Store_Theme_Enum.BlackWhitez
            }
            insertionPoint="styles-insertion-point"
          >
            <StoreFrontPage
              storeFront={selected?.username as string}
              themesView={true}
            />
          </ThemeSwitcherProvider>
        </Modal>
      )}
    </div>
  );
};
