import { Typography } from 'antd';
import Link from 'next/link';
import { DiscordLogo, TwitterLogos } from '../../icons';

export const LandingPageFooter = () => {
  return (
    <>
      <div className="p-5  flex flex-col lg:flex-row gap-10 md:py-20 items-center justify-center bg-[#2A2438]">
        <div className="flex flex-col text-center md:text-start items-center md:items-start md:w-96">
          <Typography.Title className="text-7xl text-white">
            hoji
          </Typography.Title>
          <Typography.Text className="text-white">
            The world&apos;s first and largest digital marketplace for crypto
            collectibles and non-fungible tokens (NFTs). Buy,Sell, and discover
            exclusive digital items.
          </Typography.Text>
          <div className="flex mt-2 gap-2">
            <Link href={'https://twitter.com/hojixyz'} passHref>
              <a target="_blank" rel="noopener noreferrer">
                <TwitterLogos className="text-4xl cursor-pointer" />
              </a>
            </Link>
            <Link href={'https://discord.gg/zTpUAmKRfT'} passHref>
              <a target="_blank" rel="noopener noreferrer">
                <DiscordLogo className="text-4xl cursor-pointer" />
              </a>
            </Link>
          </div>
        </div>
        <div className="md:mt-[5rem] md:w-96 text-center md:text-start">
          <Typography.Title level={4} className="text-white">
            Stay In The Loop
          </Typography.Title>
          <Typography.Text className="text-white">
            Join our mailing list to stay in the loop with our newest feature
            releases, NFT drops, and tips and tricks for navigating NFTs.
          </Typography.Text>
        </div>
      </div>
    </>
  );
};
