import { Grid } from 'antd';
import { MarketPlaceLogoAnimation } from './marketplace-logo-animation';
const { useBreakpoint } = Grid;
export function MarketPlaceAnimations() {
  const { xs } = useBreakpoint();
  return (
    <>
      <MarketPlaceLogoAnimation
        key="opensea"
        animatedX={xs ? [258, 60, -258] : [374, 60, -374]}
        animatedY={xs ? -370 : -374}
        hiddenY={xs ? -370 : -374}
        delay={8}
        duration={12}
        marketPlace="opensea"
      />
      <MarketPlaceLogoAnimation
        key="foundation"
        animatedX={xs ? [258, 204, -258] : [374, 200, -374]}
        animatedY={xs ? -370 : -374}
        hiddenY={xs ? -370 : -374}
        delay={8}
        duration={12}
        marketPlace="foundation"
      />
      <MarketPlaceLogoAnimation
        key="objkt"
        animatedX={xs ? [258, 60, -258] : [374, 60, -374]}
        animatedY={xs ? -192 : -196}
        hiddenY={xs ? -192 : -196}
        delay={8}
        duration={12}
        marketPlace="objkt"
      />
      <MarketPlaceLogoAnimation
        key="magicEden"
        animatedX={xs ? [258, 204, -258] : [374, 200, -374]}
        animatedY={xs ? -192 : -196}
        hiddenY={xs ? -192 : -196}
        delay={8}
        duration={12}
        marketPlace="magicEden"
      />
    </>
  );
}
