import { BlockchainAnimations } from '../../animations/blockchain-animations';
import { MarketPlaceAnimations } from '../../animations/marketplace-animations';
import { NFTCardAnimations } from '../../animations/nft-card-animations';
import { ProfileCardAnimation } from '../../animations/profile-card-animation';
import { ProfileBioSkeletonAnimation } from '../../animations/profile-card-animation/profile-bio-skeleton-animation';
import { ProfileCardBodySkeletonAnimation } from '../../animations/profile-card-animation/profile-card-body-skeleton-animation';
import { ProfilePictureSkeletonAnimation } from '../../animations/profile-card-animation/profile-picture-skeleton-animation';
import { ProfilePictureAnimations } from '../../animations/profile-picture-animations';

export const HeroAnimatedElement = () => {
  return (
    <div>
      <ProfileCardAnimation />
      <ProfilePictureSkeletonAnimation />
      <ProfileBioSkeletonAnimation />
      <ProfileCardBodySkeletonAnimation />
      <ProfilePictureAnimations />
      <NFTCardAnimations />
      <MarketPlaceAnimations />
      <BlockchainAnimations />
    </div>
  );
};
