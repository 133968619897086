import { Variants } from 'framer-motion';
import { profiles } from '../../../helpers/animation.helper';
import { ImageAnimationLayout } from '../image-animation-layout';
interface NFTProfilePictureAnimationProps {
  profile: string;
  animatedX: number | number[] | string;
  animatedY: number | number[] | string;
  animatedOpacity?: number | number[];
  hiddenX?: number | number[] | string;
  hiddenY: number | number[] | string;
  hiddenOpacity?: number | number[];
  delay: number;
  duration: number;
  xs?: boolean;
}

export function NFTProfilePictureAnimation({
  animatedX,
  animatedY,
  animatedOpacity = [0, 0, 1, 0, 0],
  hiddenX = '100vw',
  hiddenY,
  hiddenOpacity = 0,
  delay,
  duration,
  profile,
  xs,
}: NFTProfilePictureAnimationProps) {
  const variants: Variants = {
    hidden: {
      opacity: hiddenOpacity,
      x: hiddenX,
      y: hiddenY,
    },
    animate: {
      opacity: animatedOpacity,
      x: animatedX,
      y: animatedY,
      z: 10,
      transition: {
        delay,
        duration,
        stiffness: 40,
        repeat: Infinity,
        ...(xs ? { type: 'spring' } : { ease: 'easeInOut' }),
      },
    },
  };
  return (
    <ImageAnimationLayout
      imgProps={{
        src: profiles[profile],
        alt: profile,
        className: 'h-28 w-28 rounded-full absolute',
      }}
      variants={variants}
    />
  );
}
