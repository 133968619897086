import { Variants } from 'framer-motion';
import { ProfileCardLogo } from '../../icons/landing-page-icons';
import { AnimationLayout } from '../animation-layout';

export function ProfileCardAnimation() {
  const variants: Variants = {
    hidden: {
      opacity: 0,
      x: '100vw',
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 40,
      },
    },
  };
  return (
    <AnimationLayout variants={variants}>
      <ProfileCardLogo className="relative" />
    </AnimationLayout>
  );
}
